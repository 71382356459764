import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import CompanyName from "../assets/company-name.svg";
import RightArrow from "../assets/icons/chevron-right.svg";
import Contact from "../components/contact";
import Header from "../components/Header";
import Layout from "../components/layout";
import BigLogo from "../lotties/big-logo";
import styles from "../styles/home.module.scss";

const biggerLogo = {
  loop: true,
  autoplay: true,
  animationData: BigLogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      industryIcons: allFile(
        limit: 5
        filter: { relativeDirectory: { eq: "assets/icons/industry" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      serviceIcons: allFile(
        limit: 5
        filter: { relativeDirectory: { eq: "assets/icons/service" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);

  const ready = () => {
    document.getElementsByClassName(styles.homepage)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="Mahusai | Software Development and Design"
      description="We are a Filipino tech company that specializes in mobile and web development and design. We build interactive and immersive experiences on any platform."
    >
      <div
        className={styles.homepage}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Header />

        <div className={styles.header}>
          <CompanyName className={styles.spaceMobile} />
          <p className={styles.description}>
            We are a Filipino tech company that specializes in mobile and web
            development and design. We build interactive and immersive
            experiences on any platform.
          </p>
        </div>
        <div className={styles.bigLogo}>
          <Lottie id="animation" options={biggerLogo} height="inherit" />
        </div>

        <div className={styles.servicesList}>
          <Container fluid>
            <Row xs={1} md={12} className={styles.service}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.title}>We Develop</h3>
                <p className={styles.description}>
                  Software development is our thing. It's what we were meant to
                  do. And we do it very well. In-house you'll find the best Web,
                  Android, and iOS developers you can hope for.
                </p>
              </Col>
            </Row>
            <Row xs={1} md={12} className={styles.service}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.title}>We Design</h3>
                <p className={styles.description}>
                  Design is also our thing. In fact, it's the other thing we
                  were meant to do. You can bet that the stuff we make will not
                  only work good, but look good and feel good too.
                </p>
              </Col>
            </Row>

            <Row
              xs={1}
              md={12}
              className={`${styles.service} ${styles.servicesLink}`}
            >
              <Col xs md={{ span: 10 }}>
                <Link to="/services">
                  SERVICES <RightArrow className={styles.arrow} />
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={styles.portfolio}>
          <Container fluid>
            <Row xs={1} md={12} className={styles.portfolioRow}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.title}>Portfolio</h3>
                <p className={styles.description}>
                  We have been building products for years and have worked with
                  many clients in various industries, big and small.
                </p>
              </Col>
            </Row>

            <Row xs={1} md={12} className={styles.portfolioRow}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.portfolioTitle}>Industries</h3>
              </Col>
            </Row>
            <Row xs={2} md={12} className={styles.portfolioRow}>
              {data.industryIcons.edges.map((edge, i) => {
                const len = data.industryIcons.edges.length;
                if (i === len - 1) {
                  return (
                    <Col key={`col1_${i}`} md className="d-none d-md-block">
                      <div key={`div1_${i}`} className={styles.portfolioItem}>
                        <img
                          key={`img1_${i}`}
                          src={edge.node.publicURL}
                          alt={edge.node.name}
                        />
                        <p key={`p1_${i}`} className={styles.portfolioName}>
                          {edge.node.name.split("-").slice(1).join(" ")}
                        </p>
                      </div>
                    </Col>
                  );
                }
                return (
                  <Col key={`col1_${i}`} md>
                    <div key={`div1_${i}`} className={styles.portfolioItem}>
                      <img
                        key={`img1_${i}`}
                        src={edge.node.publicURL}
                        alt={edge.node.name}
                      />
                      <p key={`p1_${i}`} className={styles.portfolioName}>
                        {edge.node.name.split("-").slice(1).join(" ")}
                      </p>
                    </div>
                  </Col>
                );
              })}
              <Col md></Col>
            </Row>

            <Row xs={1} md={12} className={styles.portfolioRow}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.portfolioTitle}>Solutions</h3>
              </Col>
            </Row>
            <Row xs={2} md={12} className={styles.portfolioRow}>
              {data.serviceIcons.edges.map((edge, i) => {
                const len = data.serviceIcons.edges.length;
                if (i === len - 1) {
                  return (
                    <Col key={`col2_${i}`} md className="d-none d-md-block">
                      <div key={`div2_${i}`} className={styles.portfolioItem}>
                        <img
                          key={`img2_${i}`}
                          src={edge.node.publicURL}
                          alt={edge.node.name}
                        />
                        <p key={`p2_${i}`} className={styles.portfolioName}>
                          {edge.node.name.split("_").slice(1).join(" ")}
                        </p>
                      </div>
                    </Col>
                  );
                }

                return (
                  <Col key={`col2_${i}`} md>
                    <div key={`div2_${i}`} className={styles.portfolioItem}>
                      <img
                        key={`img2_${i}`}
                        src={edge.node.publicURL}
                        alt={edge.node.name}
                      />
                      <p key={`p2_${i}`} className={styles.portfolioName}>
                        {edge.node.name.split("_").slice(1).join(" ")}
                      </p>
                    </div>
                  </Col>
                );
              })}
              <Col md></Col>
            </Row>

            <Row xs={1} md={12} className={styles.portfolioRow}>
              <Col xs md={{ span: 10 }}>
                <h3 className={styles.transparent}>Learn More</h3>
              </Col>
            </Row>
            <Row xs={2} md={12} className={styles.portfolioRow}>
              {data.serviceIcons.edges.map((edge, i) => {
                const len = data.serviceIcons.edges.length;
                if (i === len - 1) {
                  return (
                    <Col key={`col3_${i}`} md>
                      <div
                        key={`div3_${i}`}
                        className={`${styles.portfolioItem} ${styles.learnMore}`}
                      >
                        <div>
                          <Link to="/portfolio">
                            LEARN MORE <RightArrow className={styles.arrow} />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  );
                } else if (i === len - 2) {
                  return (
                    <Col
                      key={`col3_${i}`}
                      md
                      className="d-none d-md-block"
                    ></Col>
                  );
                }
                return <Col key={`col3_${i}`} md></Col>;
              })}
              <Col md></Col>
            </Row>

            {/* <Row xs={1} md={12} className={styles.portfolioRow}>
              <Col
                xs
                md={{ span: 10 }}
                className={styles.learnMoreLink + " text-right"}
              >
                <div className={styles.learnMore}>
                  <Link to="/portfolio">
                    LEARN MORE <RightArrow className={styles.arrow} />
                  </Link>
                </div>
              </Col>
              <Col md></Col>
            </Row> */}
          </Container>
        </div>

        <div className={styles.aboutUs}>
          <Container fluid className={styles.aboutUsDetailsList}>
            <Row xs={1} md={12} className={styles.detail}>
              <Col xs md={{ span: 10 }}>
                <p className={styles.description}>
                  We transform your ideas into meaningful products using
                  cutting-edge technology, speedy development, and beautiful
                  design.
                </p>
              </Col>
            </Row>
            <Row xs={1} md={12} className={styles.detail}>
              <Col xs md={{ span: 10 }}>
                <p className={styles.description}>
                  We are the next generation of developers and designers –{" "}
                  <br />
                  <strong>brilliant, bold, and boundless.</strong>
                </p>
              </Col>
            </Row>

            <Row
              xs={1}
              md={12}
              className={`${styles.detail} ${styles.aboutUsLink}`}
            >
              <Col xs md={{ span: 10 }}>
                <Link to="/about">
                  ABOUT US <RightArrow className={styles.arrow} />
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Contact
          bgColorLeft="#C3E4F6"
          fontColorLeft="#4F4F4F"
          bgColorRight="#4F4F4F"
          fontColorRight="#FFFFFF"
          linkColor="#0C59AC"
        />
      </div>
    </Layout>
  );
};

export default Home;
